import routerOptions0 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}