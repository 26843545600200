<template>
    <div
        v-if="cookiesAccepted && id"
        class="vimeo-embed__container"
    >
        <figure class="vimeo-embed">
            <button
                v-if="accessibleId"
                class="vimeo-embed__accessible-switch btn--clean"
                :title="accessibleSwitchLabel"
                @click="switchVideo"
            >
                <sf-icon :icon="showAccessibleVideo ? 'visual' : 'no-visual'" />
            </button>
            <iframe
                v-show="!showAccessibleVideo"
                ref="vimeoFrame"
                frameborder="0"
                class="embed-responsive-item"
                :src="getUrl(id)"
                allow="autoplay; fullscreen"
                allowfullscreen
                :title="title || caption"
                :loading="loading"
            />
            <iframe
                v-show="showAccessibleVideo"
                ref="accessibleVimeoFrame"
                frameborder="0"
                class="embed-responsive-item"
                :src="getUrl(accessibleId)"
                allow="autoplay; fullscreen"
                allowfullscreen
                :title="title || caption"
                :loading="loading"
            />
            <figcaption
                v-if="caption"
                class="vimeo-embed__caption"
            >
                {{ caption }}
            </figcaption>
        </figure>
    </div>
    <div
        v-else
        class="vimeo-embed__blocked"
    >
        <span>We need your consent to show you this video</span>
        <sf-button
            class="dn-button--secondary"
            @click="acceptCookies"
        >
            Accept cookies
        </sf-button>
    </div>
</template>

<script setup>
import SfButton from '~/patterns/atoms/button/button.vue';
import SfIcon from '~/patterns/atoms/icon/icon';

const { onLoaded: onVimeoLoaded } = useScript('https://player.vimeo.com/api/player.js', {
    use: () => window.Vimeo
});

const { t } = useI18n({ useScope: 'local' });

const showAccessibleVideo = ref(false);

// these cannot be refs; vimeo does not like it, no idea why?!
let player = null;
let accessiblePlayer = null;

const vimeoFrame = useTemplateRef('vimeoFrame');
const accessibleVimeoFrame = useTemplateRef('accessibleVimeoFrame');

const emit = defineEmits(['play', 'pause', 'ended']);

const setupEventListeners = (_player) => {
    toValue(_player).on('play', () => {
        emit('play');
    });

    toValue(_player).on('pause', () => {
        emit('pause');
    });

    toValue(_player).on('ended', () => {
        emit('ended');
    });
};

// const COOKIE_ACCESSIBLE_VIDEO = 'showAccessibleVideos';

const switchVideo = () => {
    showAccessibleVideo.value = !showAccessibleVideo.value;
    player?.pause();
    accessiblePlayer?.pause();

    // @TODO: store show accessible video in cookie
    // this.$cookies.set(COOKIE_ACCESSIBLE_VIDEO, this.showAccessibleVideo, {
    //     maxAge: ONE_MILLENNIUM,
    //     secure: true,
    //     sameSite: 'lax'
    // });
};

const accessibleSwitchLabel = computed(() => showAccessibleVideo.value ? t('videoWithAudioDescription') : t('videoWithoutAudioDescription'));

onMounted(() => {
    onVimeoLoaded((vimeo) => {
        player = new vimeo.Player(vimeoFrame.value);
        accessiblePlayer = new vimeo.Player(accessibleVimeoFrame.value);

        setupEventListeners(player);
        setupEventListeners(accessiblePlayer);
    });
});
</script>

<script>
const VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i;

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        url: {
            type: String,
            required: true,
            default: ''
        },
        accessibleUrl: {
            type: String,
            required: false,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },

    computed: {
        id() {
            return this.getId(this.url);
        },
        accessibleId() {
            return this.getId(this.accessibleUrl);
        },
        cookiesAccepted() {
            // @TODO: connect this to the actual cookiebar
            return true;
            // return this.$cookies.get('cookie_accept_all');
        }
    },
    methods: {
        getId(url) {
            if (url && url.match(VIMEOREGEX)) {
                const matches = url.match(VIMEOREGEX);
                return matches[1];
            }

            return null;
        },
        getUrl(id) {
            return `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&color=f7f5f1&dnt=1`;
        },
        acceptCookies() {
            const options = {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365
                },
                cookieList = [
                    { name: 'cookie_accept_all', value: '1', opts: options },
                    { name: 'cookiebar_dismissed', value: '1', opts: options }
                ];
            this.$cookies.setAll(cookieList);
            this.$router.go();
        }
    }
};

</script>

<style lang="less" src="./vimeo-embed.less"></style>

<i18n>
{
    "nl": {
        "videoWithAudioDescription": "Terug naar video zonder audiodescriptie",
        "videoWithoutAudioDescription": "Video met audiodescriptie bekijken"
    },
    "en": {
        "videoWithAudioDescription": "Back to video without audio description",
        "videoWithoutAudioDescription": "Watch video with audio description"
    }
}
</i18n>
