import revive_payload_client_vDZGRA9ANN from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_c5PoAulWvr from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Whuqfj4SUC from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Lq13x7ilTm from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@20.16.10_less@4.2_xn52brz6xfjf55fb3zul2of2jm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cohKEmAMDU from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oiz77LKVAz from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kcCTiYBLEA from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wDw8DQjYgy from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ec5zXYHbU6 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_J9JrUW7c3P from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_u4TxdhN7FM from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_bej5i5v4xyhmwc262h7bx6a7bm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_KVzJsLKHCs from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.11_typescript@5.6.2___magicast@0.3_cvxlx7i4xi6ogfyouswn7xweva/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_E1OQi2I9xd from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_rr0ne3OhK4 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_nxfc6nqyt32epspjcgaekvbgaq/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_0Zrilfsm15 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import gtm_cXsUZobmSF from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/gtm.ts";
import content_placeholders_SU8YstkPji from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/content-placeholders.js";
import router_WMnTGednOQ from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/router.ts";
import v_clipboard_client_X8J8B0mEuA from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/v-clipboard.client.js";
export default [
  revive_payload_client_vDZGRA9ANN,
  unhead_c5PoAulWvr,
  router_Whuqfj4SUC,
  _0_siteConfig_Lq13x7ilTm,
  payload_client_cohKEmAMDU,
  navigation_repaint_client_oiz77LKVAz,
  check_outdated_build_client_kcCTiYBLEA,
  chunk_reload_client_wDw8DQjYgy,
  plugin_vue3_ec5zXYHbU6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J9JrUW7c3P,
  pwa_client_u4TxdhN7FM,
  plugin_KVzJsLKHCs,
  i18n_E1OQi2I9xd,
  plugin_rr0ne3OhK4,
  plugin_0Zrilfsm15,
  gtm_cXsUZobmSF,
  content_placeholders_SU8YstkPji,
  router_WMnTGednOQ,
  v_clipboard_client_X8J8B0mEuA
]