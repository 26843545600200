import { default as _91_46_46_46slug_93w5C0ZxeJhMMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue?macro=true";
import { default as _91slug_93LlYOrFFQKfMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue?macro=true";
import { default as indexyGvCuQCrYXMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue?macro=true";
import { default as discoverxmaSmpUfp8Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue?macro=true";
import { default as _91slug_93oivgQvgv0fMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue?macro=true";
import { default as indexCj3DSLNhhRMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue?macro=true";
import { default as _91slug_93QUWdOFExdxMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue?macro=true";
import { default as indexbNsFllcXSBMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue?macro=true";
import { default as _91slug_93CgLGAzithiMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue?macro=true";
import { default as indexS30Uav5P5IMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue?macro=true";
import { default as _91slug_93gd5wn6IAAyMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue?macro=true";
import { default as indexcYnQlBYU2PMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue?macro=true";
import { default as indexIMBXRDtWN6Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue?macro=true";
import { default as infoewpSFfYvDzMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue?macro=true";
import { default as _91slug_93jj8HD5cY5GMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue?macro=true";
import { default as indexsD2blp0cL4Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue?macro=true";
import { default as _91slug_93VZiXLu64LzMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue?macro=true";
import { default as indexQz6R0HHXXKMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue?macro=true";
import { default as searchyB46OtKQXNMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue?macro=true";
import { default as component_45stubBrmhhhcsYsMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBrmhhhcsYs } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_buampzd6lfzgv4ompr7yfpccri/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "articles-slug___nl",
    path: "/publicaties/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue")
  },
  {
    name: "articles-slug___en",
    path: "/en/publications/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue")
  },
  {
    name: "articles___nl",
    path: "/publicaties",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue")
  },
  {
    name: "articles___en",
    path: "/en/publications",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue")
  },
  {
    name: "discover___nl",
    path: "/ontdek",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue")
  },
  {
    name: "discover___en",
    path: "/en/discover",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue")
  },
  {
    name: "dossiers-slug___nl",
    path: "/dossiers/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue")
  },
  {
    name: "dossiers-slug___en",
    path: "/en/dossiers/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue")
  },
  {
    name: "dossiers___nl",
    path: "/dossiers",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue")
  },
  {
    name: "dossiers___en",
    path: "/en/dossiers",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue")
  },
  {
    name: "events-slug___nl",
    path: "/agenda/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue")
  },
  {
    name: "events-slug___en",
    path: "/en/agenda/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue")
  },
  {
    name: "events___nl",
    path: "/agenda",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/agenda",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue")
  },
  {
    name: "grants-issued-slug___nl",
    path: "/toekenningen/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue")
  },
  {
    name: "grants-issued-slug___en",
    path: "/en/grants-issued/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue")
  },
  {
    name: "grants-issued___nl",
    path: "/toekenningen",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue")
  },
  {
    name: "grants-issued___en",
    path: "/en/grants-issued",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue")
  },
  {
    name: "grants-slug___nl",
    path: "/subsidies/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue")
  },
  {
    name: "grants-slug___en",
    path: "/en/grants/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue")
  },
  {
    name: "grants___nl",
    path: "/subsidies",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue")
  },
  {
    name: "grants___en",
    path: "/en/grants",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue")
  },
  {
    name: "info___nl",
    path: "/info",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue")
  },
  {
    name: "info___en",
    path: "/en/info",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue")
  },
  {
    name: "news-slug___nl",
    path: "/nieuws/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue")
  },
  {
    name: "news___nl",
    path: "/nieuws",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue")
  },
  {
    name: "projects-slug___nl",
    path: "/projecten/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue")
  },
  {
    name: "projects___nl",
    path: "/projecten",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue")
  },
  {
    name: "search___nl",
    path: "/zoek",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue")
  },
  {
    name: component_45stubBrmhhhcsYsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubBrmhhhcsYs
  },
  {
    name: component_45stubBrmhhhcsYsMeta?.name,
    path: "/pages_EN-sitemap.xml",
    component: component_45stubBrmhhhcsYs
  },
  {
    name: component_45stubBrmhhhcsYsMeta?.name,
    path: "/pages_NL-sitemap.xml",
    component: component_45stubBrmhhhcsYs
  }
]